import { translate } from 'secullum-i18n';
import { DadosPeriodo, PropriedadeBatidas, TipoSolicitacao } from './types';

/**
 * Array com nome de meses abreviados em ptBR.
 */
export const monthNames = [
  'jan',
  'fev',
  'mar',
  'abr',
  'maio',
  'jun',
  'jul',
  'ago',
  'set',
  'out',
  'nov',
  'dez'
];

/**
 * Retorna os minutos de uma tempo.
 * @return
 *   Converte 1 minutos para 01:00
 */
export const convertDecimalHourToHour = (decimalTime: number) => {
  const ehNegativo = decimalTime < 0;

  decimalTime = Math.abs(decimalTime);

  let hours = Math.floor(decimalTime);
  let minutes = Math.round((decimalTime - hours) * 60);

  if (minutes === 60) {
    hours += 1;
    minutes = 0;
  }

  return (
    (ehNegativo ? '-' : '') + hours + ':' + minutes.toString().padStart(2, '0')
  );
};

/**
 * Retorna o numero da semana em relação a data informada.
 * @return
 *   Data 11/04/19 retorna 15
 */
export const getWeekNumber = (value: Date) => {
  const firstDayOfYear = new Date(value.getFullYear(), 0, 1);
  const pastDaysOfYear =
    (value.getTime() - firstDayOfYear.getTime()) / 86400000;

  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
};

/**
 * Retorna a descrição para determinada propriedade de batidas.
 * @return
 *   entrada1 retorna Entrada 1, etc...
 */
export const retornarDescricaoPropriedadeBatidas = (
  propriedadeBatidas: PropriedadeBatidas
): string => {
  const descricoesPropriedadesBatidas = {
    entrada1: `${translate('Entrada')} 1`,
    saida1: `${translate('Saída')} 1`,
    entrada2: `${translate('Entrada')} 2`,
    saida2: `${translate('Saída')} 2`,
    entrada3: `${translate('Entrada')} 3`,
    saida3: `${translate('Saída')} 3`,
    entrada4: `${translate('Entrada')} 4`,
    saida4: `${translate('Saída')} 4`,
    entrada5: `${translate('Entrada')} 5`,
    saida5: `${translate('Saída')} 5`
  };

  return descricoesPropriedadesBatidas[propriedadeBatidas];
};

/**
 * Retorna um array para percorrer as batidas
 * @return
 *   Array de 'entrada1' até 'saida3' ou 'saida5', dependendo se é 10 batidas ou não
 */
export const criarArrayPropriedadesBatidas = ({
  ponto10Batidas
}: {
  ponto10Batidas: boolean;
}): Array<PropriedadeBatidas> => {
  const arrayBatidas: Array<PropriedadeBatidas> = [
    'entrada1',
    'saida1',
    'entrada2',
    'saida2',
    'entrada3',
    'saida3'
  ];

  const arrayBatidasPonto10Batidas: Array<PropriedadeBatidas> = [
    'entrada4',
    'saida4',
    'entrada5',
    'saida5'
  ];

  return ponto10Batidas
    ? [...arrayBatidas, ...arrayBatidasPonto10Batidas]
    : arrayBatidas;
};

/**
 * Retorna uma data de acordo com o número dias atrás da data atual.
 * @return
 *   Data 29/05 se quantidade de dias for 14 retorna 14/05
 */
export const getDataInicioDiasAtras = (quantidadeDias: number) => {
  const dataIni = new Date();

  dataIni.setDate(dataIni.getDate() - quantidadeDias);

  return dataIni;
};

/**
 * Converte a data sem timezone com o time zone local do dispositivo
 * @return
 *   Data do servidor devidamente convertida para o formato com timezone.
 */
export const converterDataeHoraServidorParaDataLocal = (
  dataServidor: Date | string
): Date => {
  const timezone = minutesToHours(-new Date().getTimezoneOffset());

  if (typeof dataServidor == 'string') {
    if (!hasTimeZone(dataServidor)) {
      return new Date(dataServidor + `${timezone}`);
    }
  } else {
    return dataServidor;
  }

  return new Date(dataServidor);
};

const hasTimeZone = (dateString: string): boolean => {
  return /[+-]\d{2}:\d{2}|z$/.test(dateString);
};

function minutesToHours(minutes: number): string {
  const hours = Math.floor(minutes / 60);

  const remainingMinutes = minutes % 60;

  return `${hours < 0 ? '-' : '+'}${Math.abs(hours)
    .toString()
    .padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}`;
}

/**
 * Converte a data do servidor para a data local.
 * @return
 *   Data do servidor devidamente convertida para o formato date local.
 */
export const converterDataServidorParaDataLocal = (
  dataServidor: Date
): Date => {
  if (dataServidor && dataServidor.toString().indexOf(' ') === -1) {
    const arrDataOriginal = dataServidor.toString().split('-');
    const anoDataOriginal = parseInt(arrDataOriginal[0]);

    //O range de meses no JS é representado pelo range 0-11, como a data que vem do servidor está no range 1-12, diminui 1 para recuperar o mês corretamente
    const mesDataOriginal = parseInt(arrDataOriginal[1]) - 1;
    const diaDataOriginal = parseInt(arrDataOriginal[2]);
    return new Date(anoDataOriginal, mesDataOriginal, diaDataOriginal);
  }
  return new Date();
};

const ehHorarioDeVerao = (d: Date) => {
  const jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
  const jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
  return Math.max(jan, jul) != d.getTimezoneOffset();
};

/**
 * Converte a data  contida em um objeto do tipo Date para a data no padrão Timezone do Brasil/Brasília
 * @return
 *   Data  convertida considerando a diferença entre o timezone local e o do servidor (por padrão timezone do Brasil).
 */
export const converterDataParaTimezoneBrasil = (datetime: Date) => {
  //Recupera a data local em milisegundos usando a função getTime()
  const localtime = datetime.getTime();
  //Recupera a diferença do timezone local para o UTC e converte de milisegundos para minutos
  const localOffset = datetime.getTimezoneOffset() * 60000;
  //Obtém a hora no padrão UTC adicionando a diferença do timezone com a data local
  const utc = localtime + localOffset;

  //Define o timezone padrão do Brasil
  //Issue 3346 - O horário de verão deixou de existir no ano de 2019, com isso, quando era feita uma busca retroativa, por exemplo: estando no ano de 2020,
  //selecionar o dia 16 de fevereiro de 2019, a função retornava como resultado da conversão a data de 15/02/2019 (considerava o horário de verão).
  //Para resolver este problema foi necessário criar uma função que identifica se a data passada está no horário de verão e caso positivo, faz a conversão
  //para a data fora do horário de verão.
  const offsetBrasil = ehHorarioDeVerao(datetime) ? -2.0 : -3.0;

  //Converte o offsetBrasil para minutos e adiciona a hora local no padrão UTC, criando a data no timezone do Brasil
  const utcTimeOffsetBrasil = utc + 3600000 * offsetBrasil;
  //Converte a data do padrão minutos para Date
  const dataBraConvertida = new Date(utcTimeOffsetBrasil);

  return dataBraConvertida;
};

/**
 * Converte data e hora contida em um objeto do tipo Date para a data e hora no padrão Timezone do Brasil/Brasília
 * @return
 *   Data e hora convertida considerando a diferença entre o timezone local e o do servidor (por padrão timezone do Brasil).
 */
export const converterDataHoraParaTimezoneBrasil = (datetime: Date) => {
  const timezoneOffsetAtual = datetime.getTimezoneOffset();
  const timezoneOffsetBrasilBrasilia = 180;

  if (timezoneOffsetAtual !== timezoneOffsetBrasilBrasilia) {
    //Calcula a diferença de fuso horário em minutos do timezone local para o do Brasil(Brasília) e converte para milisegundos
    const milisegundosDiferencaFusoLocalBrasil =
      60000 * (timezoneOffsetAtual - timezoneOffsetBrasilBrasilia);

    //Ajusta a hora local somando ou diminuindo a diferença de milisegundos dos timezones
    const horaConvertida = new Date(
      datetime.getTime() + milisegundosDiferencaFusoLocalBrasil
    );

    return horaConvertida;
  }
  return datetime;
};

/*Verifica se o valor informado é hora*/
export const ehHora = (hora: string) => {
  return /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/.test(hora);
};

/**
 * Converte a lista de dados do horário separada por periodios em uma lista de horas.
 * @param listaHorarios recebe a estrutura [{horario: "08:00 - 12:00", periodo: "manhã"}]
 * @returns Lista somente com os horários ["08:00", "12:00"]
 */
export const converterDadosHorarioParaLista = (
  listaHorarios: Array<DadosPeriodo>
): Array<string> => {
  return listaHorarios
    .map(({ horario }) => horario?.split(' - ') ?? [])
    .filter(horario => horario !== null && horario !== undefined)
    .flat();
};

/**
 * Verifica se a diferença entre dois horários em formato de string é menor ou igual a um limite de minutos.
 * @param horario1 O primeiro horário em formato de string (hh:mm).
 * @param horario2 O segundo horário em formato de string (hh:mm).
 * @param minutosLimite O limite de minutos permitidos entre os horários.
 * @returns Retorna true se a diferença entre os horários for menor ou igual ao limite estabelecido.
 */
export function estaProximoHorario(
  horario1: string,
  horario2: string,
  minutosLimite: number
) {
  let diferenca = minutos(horario1) - minutos(horario2);

  // Utilizamos o Math.abs para obter o valor absoluto da diferença em minutos entre dois horários.
  // Isso é necessário para garantir que a diferença seja sempre um número positivo, independentemente da ordem em que os horários são informados.
  // Ex:
  // horario1 = 09:00
  // horario2 = 08:00
  // A diferença de minutos será de -60 e para calcular a diferença precisamos que seja positivo, neste caso o Math.abs corrige isso.
  diferenca = Math.abs(diferenca);

  // Verifica se a diferença entre os dois horários informados é maior ou igual a 1440 (24 horas em minutos) subtraído do valor de minutosLimite.
  // Se a diferença for maior ou igual a esse valor, corrige a diferença subtraindo de 1440 para que a diferença fique dentro do limite especificado.
  // Ex1: horario1 = 08:00, horario2 = 08:30, limite = 30, diferença = -30. Está dentro do limite e não precisa corrigir a diferença de dia.
  // Ex2: horario1 = 08:30, horario2 = 08:00, limite = 30, diferença = 30. Está dentro do limite e não precisa corrigir a diferença de dia.
  // Ex3: horario1 = 23:55, horario2 = 00:05, limite = 30, diferença = 1430 (1440 - 1430 = 10 min). Está dentro do limite e precisa corrigir a diferença de dia.
  // Ex4: horario1 = 00:15, horario2 = 23:45, limite = 30, diferença = -1410 (1440 - 1410 = 30 min). Está dentro do limite e precisa corrigir a diferença de dia.
  if (diferenca >= 1440 - minutosLimite) {
    diferenca = 1440 - diferenca;
  }

  return diferenca <= minutosLimite;

  function minutos(hora: string) {
    const h = Number(hora.substring(0, 2));
    const m = Number(hora.substring(3, 5));

    return h * 60 + m;
  }
}

/**
 * Retorna a data atual desconsiderando as horas.
 * @return
 *   Data atual com as horas do dia como 00:00:00
 */
export const getDataSemHoras = () => {
  const dataAtual = new Date();

  return new Date(
    dataAtual.getFullYear(),
    dataAtual.getMonth(),
    dataAtual.getDate()
  );
};

// Constante que verifica se o navegador usado pelo usuário é o Firefox.
export function isFirefox() {
  return /firefox/i.test(navigator.userAgent);
}

export function ehSolicitacaoAfastamento(tipoSolicitacao: TipoSolicitacao) {
  return (
    tipoSolicitacao === TipoSolicitacao.Afastamento ||
    tipoSolicitacao === TipoSolicitacao.ExclusaoAfastamento
  );
}
